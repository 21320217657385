
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { TeamModule } from '../../store'
import ListItem from './list-item.vue'

@Component({
  components: {
    ListItem
  },
  name: 'TeamFilter'
})
export default class extends Vue {
  teamModule = getModule(TeamModule, this.$store)

  searchTerm = ''
  searchTermModel = ''

  get teamsFuzzy () {
    if (this.searchTerm) {
      const fuse = new Fuse(this.teamModule.teams, {
        keys: [
          'name'
        ],
        threshold: 0.3
      })
      return fuse.search(this.searchTerm).map(o => o.item)
    } else {
      return this.teamModule.teams
    }
  }

  opened () {
    this.searchTerm = ''
    this.searchTermModel = ''
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }
}
