
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'TagDeleteDialog'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentTagHandleId () {
    return this.$queryValue('tag_delete_dialog')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentTag () {
    return Object.values(this.tagModule.tags).find(o => o.handleId === this.currentTagHandleId)
  }

  get currentTagGroup () {
    return Object.values(this.tagModule.tagGroups).find(o => o.id === this.currentTag?.groupId)
  }

  opened () {
    analytics.tagDeleteDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  async deleteTag () {
    const currentTag = this.currentTag
    const currentTagGroup = this.currentTagGroup
    if (currentTag && currentTagGroup) {
      const revertTasks: Task[] = [{
        id: currentTag.id,
        props: {
          color: currentTag.color,
          groupId: currentTag.groupId,
          handleId: currentTag.handleId,
          index: currentTag.index,
          name: currentTag.name
        },
        type: 'tag-create'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            tag_delete_dialog: null
          }
        },
        type: 'navigation'
      }]

      await this.$replaceQuery({
        overlay_hide: this.$removeQueryArray(currentTag.handleId),
        overlay_pin: this.$removeQueryArray(currentTag.handleId)
      })

      this.tagModule.removeTag(currentTag.id)
      this.editorModule.addToTaskQueue({
        func: () => this.tagModule.tagDelete({
          landscapeId: this.currentLandscape.id,
          tagId: currentTag.id,
          versionId: this.currentVersion.id
        })
      })

      analytics.tagDelete.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagColor: currentTag.color,
        tagGroupIcon: currentTagGroup.icon,
        tagGroupName: currentTagGroup.name,
        tagName: currentTag.name,
        tagNameLength: currentTag.name.length
      })

      await this.$replaceQuery({
        tag_delete_dialog: undefined
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: currentTag.id,
          type: 'tag-delete'
        }, {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              tag_delete_dialog: null
            }
          },
          type: 'navigation'
        }]
      })
    }
  }
}
