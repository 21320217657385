
import { TagGroup, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'TagGroupDeleteDialog'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get tagGroupHandleId () {
    return this.$queryValue('tag_group_delete_dialog')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentTagGroup () {
    return Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.tagGroupHandleId)
  }

  get currentTags () {
    return Object.values(this.tagModule.tags).filter(o => o.groupId === this.currentTagGroup?.id)
  }

  opened () {
    analytics.tagGroupDeleteDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  async deleteTagGroup () {
    const currentTagGroup = this.currentTagGroup
    if (currentTagGroup) {
      const tagIds = this.currentTags.map(o => o.id)

      const revertTasks: Task[] = [
        {
          id: currentTagGroup.id,
          props: {
            handleId: currentTagGroup.handleId,
            icon: currentTagGroup.icon,
            index: currentTagGroup.index,
            name: currentTagGroup.name
          },
          type: 'tag-group-create'
        },
        {
          tasks: this.currentTags.map((o): Task => ({
            id: o.id,
            props: {
              color: o.color,
              groupId: o.groupId,
              handleId: o.handleId,
              index: o.index,
              name: o.name
            },
            type: 'tag-create'
          })),
          type: 'batch'
        },
        {
          tasks: [
            ...Object
              .values(this.modelModule.objects)
              .filter(o => o.tagIds.some(t => tagIds.includes(t)))
              .map((o): Task => ({
                id: o.id,
                props: {
                  tagIds: o.tagIds
                },
                type: 'model-object-update'
              })),
            ...Object
              .values(this.modelModule.connections)
              .filter(o => o.tagIds.some(t => tagIds.includes(t)))
              .map((o): Task => ({
                id: o.id,
                props: {
                  tagIds: o.tagIds
                },
                type: 'model-connection-update'
              }))
          ],
          type: 'batch'
        },
        {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              tag_group_delete_dialog: null
            }
          },
          type: 'navigation'
        }
      ]

      const query: any = {
        overlay_focus: this.$removeQueryArray(...this.currentTags.map(o => o.handleId)),
        overlay_hide: this.$removeQueryArray(...this.currentTags.map(o => o.handleId)),
        overlay_pin: this.$removeQueryArray(...this.currentTags.map(o => o.handleId)),
        tag_group_delete_dialog: undefined
      }
      if (this.overlayGroupId === this.tagGroupHandleId) {
        query.overlay_group = Object
          .values(this.tagModule.tagGroups)
          .filter(o => o.id !== currentTagGroup.id)
          .reduce<TagGroup | undefined>((c, p) => c && c.index < p.index ? c : p, undefined)?.handleId
      }

      tagIds.forEach(o => this.tagModule.removeTag(o))
      this.tagModule.removeTagGroup(currentTagGroup.id)
      this.editorModule.addToTaskQueue({
        func: () => this.tagModule.tagGroupDelete({
          landscapeId: this.currentLandscape.id,
          tagGroupId: currentTagGroup.id,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [
          {
            id: currentTagGroup.id,
            type: 'tag-group-delete'
          },
          {
            route: {
              ...this.$route,
              query: {
                ...this.$route.query,
                tag_group_delete_dialog: null
              }
            },
            type: 'navigation'
          }
        ]
      })

      analytics.tagGroupDelete.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagGroupIcon: currentTagGroup.icon,
        tagGroupName: currentTagGroup.name,
        tagGroupNameLength: currentTagGroup.name.length
      })

      await this.$replaceQuery(query)
    }
  }
}
