
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { TagModule } from '@/modules/tag/store'

import GroupListItem from './group-list-item.vue'

@Component({
  components: {
    GroupListItem
  },
  name: 'TagFilter'
})
export default class extends Vue {
  tagModule = getModule(TagModule, this.$store)

  searchTerm = ''
  searchTermModel = ''

  get tagGroups () {
    return Object
      .values(this.tagModule.tagGroups)
      .map(o => {
        const tags = Object
          .values(this.tagModule.tags)
          .filter(t => t.groupId === o.id)
          .sort((a, b) => {
            if (a.index === b.index) {
              if (a.name === b.name) {
                return a.id.localeCompare(b.id)
              } else {
                return a.name.localeCompare(b.name)
              }
            } else {
              return a.index - b.index
            }
          })
          .map(t => ({
            ...t,
            groupName: o.name
          }))

        if (this.searchTerm) {
          const fuse = new Fuse(tags, {
            keys: [
              'name',
              'groupName'
            ],
            threshold: 0.3
          })
          return {
            group: o,
            tags: fuse.search(this.searchTerm).map(o => o.item)
          }
        } else {
          return {
            group: o,
            tags
          }
        }
      })
      .filter(o => o.tags.length)
  }

  opened () {
    this.searchTerm = ''
    this.searchTermModel = ''
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }
}
