
import { Team } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import TagChip from '@/modules/tag/components/tag.vue'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { TeamModule } from '../../store'

@Component({
  components: {
    TagChip
  },
  name: 'TeamFilterListItem'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly team!: Team

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get filterTeam () {
    return this.$queryArray('filter')
  }

  get filterExcludeTeam () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.filterTeam.length || this.filterExcludeTeam.length) && !this.filtered && !this.excluded ? 0.5 : 1
  }

  get filtered () {
    return this.filterTeam.includes(this.team.id)
  }

  get excluded () {
    return this.filterExcludeTeam.includes(this.team.id)
  }

  get teamIds () {
    return this.teamModule.teams.map(o => o.id)
  }

  toggleTeamFilter () {
    if (this.filtered) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.team.id),
        filter_exclude: this.$removeQueryArray(this.team.id)
      })
    } else {
      this.$replaceQuery({
        filter: {
          ...this.$unionQueryArray(this.team.id),
          ...this.$removeQueryArray(...this.teamIds.filter(o => o !== this.team.id))
        },
        filter_exclude: this.$removeQueryArray(this.team.id)
      })

      analytics.teamFilter.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  toggleTeamExclude () {
    if (this.excluded) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.team.id),
        filter_exclude: this.$removeQueryArray(this.team.id)
      })
    } else {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.team.id),
        filter_exclude: this.$unionQueryArray(this.team.id)
      })

      analytics.teamFilterExclude.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
