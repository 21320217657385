
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { Tag, TagGroup } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import TagColorMenu from '../color-menu.vue'
import TagChip from '../tag.vue'

@Component({
  components: {
    TagChip,
    TagColorMenu
  },
  name: 'TagFilterMenuTagListItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly group!: TagGroup
  @Prop() readonly tag!: Tag

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get includeTag () {
    return this.$queryArray('filter_include')
  }

  get excludeTag () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.includeTag.length || this.excludeTag.length) && !this.included && !this.excluded ? 0.5 : 1
  }

  get colorMap () {
    return (this.includeTag.length || this.excludeTag.length) && (this.included || this.excluded) ? TAG_COLOR_ACTIVE : TAG_COLOR_INACTIVE
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get icon () {
    return icons.tagGroups[this.group.icon]
  }

  get count () {
    return Object.values(this.modelModule.objects || {}).filter(o => o.tagIds.includes(this.tag.id)).length
  }

  get included () {
    return this.includeTag.includes(this.tag.handleId)
  }

  get excluded () {
    return this.excludeTag.includes(this.tag.handleId)
  }

  toggleTagInclude () {
    if (this.included) {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.tag.handleId),
        filter_include: this.$removeQueryArray(this.tag.handleId)
      })
    } else {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.tag.handleId),
        filter_include: this.$unionQueryArray(this.tag.handleId)
      })

      analytics.tagFilterInclude.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagColor: this.tag.color,
        tagGroupIcon: this.group.icon,
        tagGroupName: this.group.name,
        tagName: this.tag.name
      })
    }
  }

  toggleTagExclude () {
    if (this.excluded) {
      this.$replaceQuery({
        filter_exclude: this.$removeQueryArray(this.tag.handleId),
        filter_include: this.$removeQueryArray(this.tag.handleId)
      })
    } else {
      this.$replaceQuery({
        filter_exclude: this.$unionQueryArray(this.tag.handleId),
        filter_include: this.$removeQueryArray(this.tag.handleId)
      })

      analytics.tagFilterExclude.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagColor: this.tag.color,
        tagGroupIcon: this.group.icon,
        tagGroupName: this.group.name,
        tagName: this.tag.name
      })
    }
  }
}
